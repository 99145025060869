//****** Automation Match Criteria ******//
export type MatchCriteriaType = {
  RECORD: {
    useViewsAsFilters: boolean
    selectedView: RestrictToViewOption
    requirements: RequirementGroupType[]
  }
  FIELD: {
    fieldsToWatch: FieldsToWatchType[]
    restrictToView: ValueLabelType
  }
  // Use for triggers relating to periodic time events
  // Cases are:
  // - Every X minutes
  // - Every X hours
  // - Every X days, at time Y
  // - Every X weeks, at time Y, on day (mon, tue...) Z
  // - Every X months, at time Y, on day (1,2,3....) Z
  // - Every X years, at time Y, on day (1,2,3....) Z, in month (jan, feb...) W
  // - Once at a specific time and date
  TEMPORAL?: {
    temporalType: TemporalTriggerType // The type of temporal trigger as defined in the enum above
    interval: number // The interval at which the trigger should happen. For example "every _5_ minutes"
    timeOfDay?: string // If the trigger is day or larger, the time of day the trigger should happen
    dayOfWeek: number[] // If the trigger is weekly, the day(s) of the week the trigger should happen. Needs to also have a timeOfDay
    dayOfMonth: number[] // If the trigger is monthly or yearly, the day(s) of the month the trigger should happen. Needs to also have a timeOfDay
    monthOfYear: number[] // If the trigger is yearly, the month of the year the trigger should happen. Needs to also have a dayOfMonth and timeOfDay
    startDate?: string // The start date of the trigger. Must be in the future when created by at least 30 minutes (to ensure queuer doesn't miss it). If the temporalType is ONCE, this is the date the trigger should happen
  }
  // Used after a trigger has happened. The trigger is anything that happens in the table that the automation is watching
  // This advanced logic is baserow specific
  // So, if a trigger happens and there is advanced logic
  advancedLogic: AdvancedLogicObject
}

// Used after a trigger has happened. The trigger is anything that happens in the table that the automation is watching
// This advanced logic is baserow specific
// So, if a trigger happens and there is advanced logic
export type AdvancedLogicObject = {
  useAdvancedLogic: boolean // True if user wants advanced logic
  advancedLogicType: AdvancedLogicType // Define the advanced logic type. For now, this is FIND only
  // If the user selects FIND (currently the only option)
  // There are two types of find:
  // - We find records linked to the TRIGGER RECORD; or
  // - We find records from a given base/table and potentially view
  FIND: {
    TYPE: AdvancedLogicFindType // Using the enum below defines if the find is based on linked records or a general find of unrelated objects
    // This defines the properties of the link. The link has a table that the record links to, as well as the value (the field id) and the label (the field name)
    linkedFieldData?: LinkedFieldForFindType
    // This defines the properties of the general find. It contains the base Id, table Id and view Id to search for records.
    // Note that only up to 100 records are returned, so we need to alert the user of this restriction. Ideally, we can provide filters on the query to help
    // reduce the number of records returned, but for now we don't have that luxury.
    findRecordData?: FindRecordsForFindType

    // Now, based on the FOUND records, we can filter further. The filters allow the user to refine the records found to be filtered by field values. For example,
    // Finding all records with a status of ACTIVE
    filters?: RequirementGroupType[]

    // A selector is used to select a SPECIFIC record based on the enum value used. The record selected is then used to apply actions
    // The selector can be used IN CONJUNCTION with filters. For example, a selector of LAST MODIFIED used in conjuntion with a filter of Status==="Active"
    // Means that it will select the last modified record that is active
    selector?: FindSelectorType
    // Now because baserow doesn't allow us to get metadata about a record in the same way that airtable does, we need to provide a way to get the data.
    // So a simple solution is to make the user provide the id of a field that contains the metadata (modified or created time) so we can then perform the operation
    selectorField?: {
      value: string
      label: string
    }
  }
}

// Used when the automation is watching a field. This can be used as a list if the user is watching multiple fields
export type FieldsToWatchType = {
  id: number
  name: string
  type: string
}

// Used when the automation is watching a field and that watch is restricted to a view
export type RestrictToViewOption = ValueLabelType & {
  slug: string
}
export type ValueLabelType = {
  value: string
  label: string
}

// Used when the automation is filtered by certain field values defined on the front end
export type RequirementGroupType = {
  rule?: 'AND' | 'OR'
  id?: string
  requirements?: RequirementType[] | RequirementGroupType[]
}
export type RequirementType = {
  id: string
  fieldName: string
  valueLabel: string
  fieldId: string
  fieldType: string
  filter: FieldDefinitionOption
}

// This is the definition of a field that can be used in the filter or the action. It holds meta data used by the automation to perform actions
export type FieldDefinitionOption = {
  value: string
  label: string
  params: Record<number, ParamType> // Use ParamType here
}

// Currently unused but was used to define how often an automation should run when using the TEMPORAL trigger type
export enum TemporalTriggerType {
  'MINUTE' = 'MINUTE',
  'HOUR' = 'HOUR',
  'DAY' = 'DAY',
  'WEEK' = 'WEEK',
  'MONTH' = 'MONTH',
  'YEAR' = 'YEAR',
  'ONCE' = 'ONCE',
}

// The different types of advanced logic that can be used
export enum AdvancedLogicType {
  FIND = 'FIND',
}

// The different types of find that can be used with advanced logic
export enum AdvancedLogicFindType {
  LINKED_FIELD = 'LINKED_FIELD',
  FIND_RECORDS = 'FIND_RECORDS',
}

// When using the FIND advanced logic, the user selects a field to link to the secondary (lookup) table
export type LinkedFieldForFindType = {
  value: number
  label: string
  linkedToTable: number
  type?: string
}

// When using the FIND advanced logic, this contains the information used to find the actual records that will then be linked
export type FindRecordsForFindType = {
  baseId: string
  tableId: string
  viewId?: string
}

// When records are found using the FIND advanced logic, the user can further filter the records found like in Airtable
export enum FindSelectorType {
  LAST_MODIFIED = 'LAST_MODIFIED',
  LAST_CREATED = 'LAST_CREATED',
  FIRST_MODIFIED = 'FIRST_MODIFIED',
  FIRST_CREATED = 'FIRST_CREATED',
  ALL_RECORDS = 'ALL_RECORDS',
}
//****************************************************************************************************//

//****** Automation Action ******//
// The base action structure used. In the db this is a list as there are multiple actions that can be run
export type Action = {
  data: ActionData[]
  notifyUserData: AutomationUserNotificationDataType[] // Notification Actions
  order: number
  action: ActionExecutionType
  outputTableId?: number
  automationTimezone: string
}

// This defines an individual action item. This is used in the action list above
export type ActionData = {
  fieldName: string
  fieldId: string
  fieldType: string
  inputType: FieldDefinitionOption
}

// This is the meta data stored from the front end that the automation uses to perform actions
export type ParamType = {
  type: 'input' | 'options' | 'copy' | 'dynamic'
  dataType: string
  isConditional: boolean
  label: string
  value?: any
  valueLabel?: string
  copyType?: string
  numSteps?: number // Optional, only for `setTo` and similar cases
  options?: string[] // List of valid options
  dependency?: {
    reference: number // Refers to another parameter by key
    conditions: Record<string, { dataType: string }>
  }
}

// The types of automation actions that can be performed
export enum ActionExecutionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  UPDATE_FROM_ADVANCED_LOGIC = 'UPDATE_FROM_ADVANCED_LOGIC',
  SEND_NOTIFICATION = 'SEND_NOTIFICATION',
  SEND_EMAIL = 'SEND_EMAIL',
}

export enum AutomationsMemberNotificationSendType {
  RECIPIENT = 'RECIPIENT',
  CC = 'CC',
  BCC = 'BCC',
}

// When using notifications or emails, this data structure defines the data needed to send the notification
export type AutomationUserNotificationDataType = {
  matchingData: AutomationsUserNotificationType
  notificationMessage: string
  notificationTitle: string
  customFromName?: string
}

// This is the data structure used to match the user to send the notification to
export type AutomationsUserNotificationType = {
  matchValues: AutomationNotificationMatchType[]
  matchOn: AutomationsMemberNotificationMatchOnType
  matchType: AutomationsMemberNotificationMatchType
}

// The different types of matching that can be used to send a notification. This match is used to determine who to send the notification to
export enum AutomationsMemberNotificationMatchOnType {
  MEMBERSHIP_IDS = 'MEMBERSHIP_IDS',
  WORKFLOW_FIELD_VALUE = 'WORKFLOW_FIELD_VALUE',
}

// This is the different types of matching that can be used to send a notification. This match is used to determine how to match the user from the baserow data
export enum AutomationsMemberNotificationMatchType {
  'EMAIL' = 'EMAIL',
  'FULL_NAME' = 'FULL_NAME',
  'MEMBERSHIP_ID' = 'MEMBERSHIP_ID',
  'BASEROW_COLLABORATOR' = 'BASEROW_COLLABORATOR',
}

// Generic type used for select options
type SelectOption = {
  label: string
  value: string
}

type AutomationNotificationMatchType = SelectOption & {
  sendType?: AutomationsMemberNotificationSendType
}

//****************************************************************************************************//

//****** Additional types used here and throughout the code base ******//
// So we can try to rid the code of any 'any' types. These are baserow structures that are hard/unnecessary to define
export type BaserowRecordType = Record<string, any>
export type BaserowFilterType = Record<string, any>

// The output of the automation. This defines the standard message that is returned to the user
export enum AutomationOutcomes {
  SUCCESSFUL = 'Processed Successfully',
  FAILED = 'Processing Failed',
}

// Options for when grouped filters are used
export type RuleType = 'AND' | 'OR'

// Used to store the information required to write a record to Baserow
export type AutomationDataToBaserowType = {
  recordData: BaserowRecordType
  rowId: number
  tableId: number
  baseId: number
}

// The result from writing to Baserow. This is used to determine if the action was successful
export type AutomationReturnData = {
  message: string
  success: boolean
  returnedRecord?: BaserowRecordType
}

// The type of the giant structure we use to determine field options used in the automation
export type AutomationFieldDefinitionTypes = {
  value: string
  label: string
  inputOptions: FieldDefinitionOption[]
  filterOptions: FieldDefinitionOption[]
}

//****************************************************************************************************//

//****** From Automation Webhook ******//
export type TriggerListDataType = TriggerRecordData & TriggerMetadata

// The data that represents the record before and after the change
export type TriggerRecordData = {
  newData: BaserowRecordType
  oldData?: BaserowRecordType
}

// The metadata that is sent with the trigger. This is used to determine what triggered the automation
export type TriggerMetadata = {
  eventType: string
  tableId: string
  baseId: number
  recordId?: string
  eventId: string
}

// Defines the results of the match criteria for an automation once it has been evaluated
export type AutomationLogCriteriaResultsType = {
  oldData: AutomationLogCriteriaResultItemType
  newData: AutomationLogCriteriaResultItemType
}

// The result of an evaluation. Evaluations are performed on the old data (before the change) and the new data (after the change)
export type AutomationLogCriteriaResultItemType = {
  success: boolean
  results: AutomationLogCriteriaResultItemFieldType[]
}

// Each filter/check is applied to a field in the record. This is the result of an individual check
export type AutomationLogCriteriaResultItemFieldType = {
  success: boolean
  fieldId: string
  fieldName: string
  fieldValue: string
}

//********************************************************************************************************************//

//****** Automation Log Trigger Data Results ******//
// This structure is used to store all of the information about the trigger that caused the automation to run
export type AutomationLogTriggerDataType = {
  triggerType: AutomationTriggerType
  originalData: AutomationLogFieldStringType
  triggerRowId: number
  triggerBaseId: number
  triggerViewId: string
  triggerTableId: string
  triggerTableName: string
  triggerViewName: string
  triggerMainFieldId: number
  triggerUsesViewData: boolean
  triggerFieldsToWatch: FieldsToWatchType[]
  triggerMainFieldName: string
  triggerRestrictToView?: ValueLabelType
}

// We shorten/stringify the data so that it can be displayed in the log modal
export type AutomationLogFieldStringType = Record<
  string,
  { truncatedStringValue: string }
>

// The type of trigger that caused the automation to run
export enum AutomationTriggerType {
  RECORD = 'RECORD',
  FIELD = 'FIELD',
  TEMPORAL = 'TEMPORAL',
  TIMED_VIEW = 'TIMED_VIEW',
}

//********************************************************************************************************************//

//****** Automation Log Action Data Results ******//

// This structure stores the information about the actions that were performed. In the db this is an array as more than one action can be performed
export type AutomationLogActionItemType = {
  message: string
  actionType: ActionExecutionType
  completedAt: string
  actionSuccess: boolean
  actionStatus: AutomationLogStatus
  actionedRowId: number
  actionedBaseId: number
  actionedTableId: number
  actionedTableName: string
  actionedMainFieldId: number
  actionedMainFieldName: string
  actionData: AutomationLogFieldStringType
}

// The different statuses that an action can have
export enum AutomationLogStatus {
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  PARTIAL = 'PARTIAL',
  FAILED = 'FAILED',
}
